<!--  -->
<template>
  <div class="leftThree">
    <div class="chart-title">
      安全报警走势图
    </div>
    <div id="leftThreeCharts">

    </div>
  </div>
</template>

<script>
export default {
  name: "leftThree",
  props: {

  },
  watch: {

  },
  computed: {

  },
  data() {
    return {
      option: null,
      myLeftThreeCharts: null
    }
  },
  created() {
    if (!this.myLeftThreeCharts) {

      this.$nextTick(() => {

        this.option = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            top: 20,
            right: 10,
            textStyle: {
              color: '#fff'
            },
            data: ['2024','2023'],

          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11','12'],
            name: '月',
            nameTextStyle: {
              color: '#fff'
            },
            axisLabel: {
              textStyle:{
                color: '#fff'
              }
            },
            axisTick: {
              show: false
            },
            splitLine: { //设置X轴的网格
              show: false,
              interval: 'auto'
            },
            axisLine: { //设置X轴的颜色
              show: false,
            }
          },
          yAxis: {
            type: 'value',
            name: '告警数',
            nameTextStyle: {
             color: '#fff',
             fontSize:10
            },
            axisLabel: {
              textStyle:{
                color: '#fff'
              }
            },
            splitLine: { //设置X轴的网格
              show: false,
              interval: 'auto'
            },
            axisLine: { //设置y轴的颜色
              show: false,
            }
          },
          series: [{
            name: '2024',
            type: 'line',
            stack: '总量',
            smooth: true, //点与点之间的幅度,false为直线
            // symbol: 'circle',
            // symbolSize: 5,
            // showSymbol: true,   //是否显示点数据的圆点
            itemStyle: {    //设置折线的border颜色
                normal: {
                    color: '#F3904A',
                    borderColor: 'rgba(0,136,212,0.2)',
                    borderWidth: 10
                }
            },
            lineStyle:{
              color: '#FF8077'
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(255, 158, 68,1)'
                }, {
                  offset: 1,
                  color: 'rgba(255, 70, 131,0.1)'
                }])
              }
            },
            label: { //设置点数据的提示
              normal: {
                show: false,
                position: 'bottom'
              }
            },
            data: [2,  ],
            markPoint: { //设置最大值和最小值
              data: [{
                type: 'max',
                name: '最大值'
              }, {
                type: 'min',
                name: '最小值'
              }]
            }

          },
          {
            name: '2023',
            type: 'line',
            stack: '总量',
            smooth: true, //点与点之间的幅度,false为直线
            // symbol: 'circle',
            // symbolSize: 5,
            // showSymbol: true,   //是否显示点数据的圆点
            itemStyle: {    //设置折线的border颜色
                normal: {
                    color: '#F33B2C',
                    borderColor: 'rgba(0,136,212,0.2)',
                    borderWidth: 10
                }
            },
            lineStyle:{
              color: '#FF8077'
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(255, 158, 68,1)'
                }, {
                  offset: 1,
                  color: 'rgba(255, 70, 131,0.1)'
                }])
              }
            },
            label: { //设置点数据的提示
              normal: {
                show: false,
                position: 'bottom'
              }
            },
            data: [1, 5, 1, 4, 6, 7, 5,4, 3, 1, 2, 3,],
            markPoint: { //设置最大值和最小值
              data: [{
                type: 'max',
                name: '最大值'
              }, {
                type: 'min',
                name: '最小值'
              }]
            }

          }]
        };
        this.myLeftTwoCharts = this.$echarts.init(document.querySelector('#leftThreeCharts'))
        this.myLeftTwoCharts.setOption(this.option)
      })
    }
  },
  mounted() {

  },
  methods: {
    resize() {
      this.myLeftThreeCharts.resize()
    }
  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.leftThree {
  display: flex;
  flex-direction: column;
}

#leftThreeCharts {
  width: 412px;
  height: 244px;
  background: url("../../assets/img/charts_bg.png");
  background-size: cover;
}

.chart-title {
  font-size: 20px;
  font-family: FZZ;
  font-weight: 700;
  width: 385px;
  height: 37.17px;
  color: #fff;
  font-style: italic;
  padding-left: 70px;
  background: url("../../assets/img/charts_title.png");
  background-size: 440px 100%;
  margin-bottom: 12px;
}
</style>