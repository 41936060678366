<!--  -->
<template>
  <div class="rightThree">
    <div class="chart-title">
      电气火灾告警率按单位类型统计图
    </div>
    <div id="rightThreeCharts">

    </div>
  </div>
</template>

<script>
export default {
  name: "rightThree",
  props: {

  },
  watch: {

  },
  computed: {

  },
  data() {
    return {
      option: null,
      myRightThreeCharts: null
    }
  },
  created() {
    if (!this.myRightThreeCharts) {
      this.$nextTick(async () => {
        this.option = {
          color: ['#F12A00','#F56302','#FFCC20','#FFEF26','#76D201','#01A7E7',
                  '#6667FF','#D66AE4','#E747AC','#1A3B75'],
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: 220,
            right: 2,
            height: 100,
            itemGap: 20,
            itemWidth: 20,
            itemHeight: 10,
            textStyle: {
              color: '#fff',
              lineHeight: 1
            }
          },
          graphic: [
            {
              type: 'circle',
              left: 'center',
              top: 75,
              z: 999,
              shape: {
                r: 35
              },
              style: {
                fill: 'rgba(255,255,255, 0.1)',
              }
            },
            {
              type: 'circle',
              left: 'center',
              top: 30,
              z: -999,
              shape: {
                r: 80
              },
              style: {
                fill: 'rgba(255,255,255, 0.1)',
              }
            },
          ],
          series: [
            {
              name: '类型',
              type: 'pie',
              radius: [25, 65],
              top: -80,
              // center: ['center', '50%'],
              // left: 10,
              label: {
                show: true,
                formatter: '{b}:{c}',
                textStyle: {
                  color: '#fff'
                }
              },
              labelLine: {
                show: true,
                
              },
              data: [
                { value: 1048, name: '工厂企业' },
                { value: 735, name: '教育机构' },
                { value: 580, name: '医疗机构' },
                { value: 484, name: '民政机构' },
                { value: 300, name: '政府部门' },
                { value: 343, name: '商业场所' },
                { value: 879, name: '人员密集场所' },
                { value: 435, name: '住宅小区' },
                { value: 432, name: '养老机构' },
                { value: 787, name: '其他' }
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
        this.myRightThreeCharts = this.$echarts.init(document.querySelector('#rightThreeCharts'))
        this.myRightThreeCharts.setOption(this.option)
      })
    }
  },
  mounted() {

  },
  methods: {
    resize() {
      this.myRightThreeCharts.resize()
    }
  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.rightThree {
  display: flex;
  flex-direction: column;
}

#rightThreeCharts {
  width: 385px;
  height: 300px;
  // background-color: rgba(0, 0, 0, 0.03);
  background: url("../../assets/img/charts_bg.png");
  background-size: cover;
}

.chart-title {
  font-size: 20px;
  font-family: FZZ;
  font-weight: 700;
  width: 385px;
  height: 37.17px;
  color: #fff;
  font-style: italic;
  padding-left: 70px;
  background: url("../../assets/img/charts_title.png");
  background-size: 440px 100%;
  margin-bottom: 12px;
}
</style>