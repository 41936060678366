<!--  -->
<template>
  <div class="middleMapTop">
    <div class="middleMapTop-uv">
      <div class="middleMapTop-uv-title">
        未处理报警
      </div>
      <div class="middleMapTop-uv-num">
        <div class="middleMapTop-uv-num-item" v-for="item in alarmNum">
          <transition name="fade">
            <div v-show="show"> {{ item }}</div>
          </transition>
        </div>
      </div>
    </div>
    <div class="middleMapTop-pv">
      <div class="middleMapTop-pv-title">
        未处理隐患
      </div>
      <div class="middleMapTop-pv-num">
        <div class="middleMapTop-pv-num-item" v-for="item in troubleNum">
          {{ item }}
        </div>
      </div>
    </div>
    <div id="middleMapTop">

    </div>
  </div>
</template>

<script>
import xuzhou from '../../assets/geo/xuzhou/xuzhou.json'
import chinaMap from "../../assets/geo/china.json";
export default {
  name: "middleMapTop",
  components: {

  },
  props: {

  },
  watch: {

  },
  computed: {

  },
  data() {
    return {
      option: null,
      myMiddleMapCharts: null,
      alarmNum: '',
      troubleNum: '',
      chinaMap,
      show: true
    }
  },
  created() {
    this.alarmNum = '222'
    this.alarmNum = this.alarmNum.padStart(5,'0')
    this.troubleNum = '222'
    this.troubleNum = this.troubleNum.padStart(5,'0')


    if (!this.myMiddleMapCharts) {
      this.$nextTick(async () => {

        this.$echarts.registerMap("china", { geoJSON: this.chinaMap });

        var mapName = 'china'
        var data = [
          { name: "北京市", value: 199 },
          { name: "天津市", value: 42 },
          { name: "河北省", value: 102 },
          { name: "山西省", value: 81 },
          { name: "内蒙古自治区", value: 47 },
          { name: "辽宁省", value: 67 },
          { name: "吉林省", value: 82 },
          { name: "黑龙江省", value: 123 },
          { name: "上海市", value: 24 },
          { name: "江苏省", value: 92 },
          { name: "浙江省", value: 114 },
          { name: "安徽省", value: 109 },
          { name: "福建省", value: 116 },
          { name: "江西省", value: 91 },
          { name: "山东省", value: 119 },
          { name: "河南省", value: 137 },
          { name: "湖北省", value: 116 },
          { name: "湖南省", value: 114 },
          { name: "重庆市", value: 91 },
          { name: "四川省", value: 125 },
          { name: "贵州省", value: 62 },
          { name: "云南省", value: 83 },
          { name: "西藏自治区", value: 9 },
          { name: "陕西省", value: 80 },
          { name: "甘肃省", value: 56 },
          { name: "青海省", value: 10 },
          { name: "宁夏回族自治区", value: 18 },
          { name: "新疆维吾尔自治区", value: 180 },
          { name: "广东省", value: 123 },
          { name: "广西省", value: 59 },
          { name: "海南省", value: 14 },
        ];

        var geoCoordMap = {};
        var toolTipData = [
          { name: "北京市", value: [{ name: "科技人才总数", value: 95 }, { name: "理科", value: 82 }] },
          { name: "天津市", value: [{ name: "文科", value: 22 }, { name: "理科", value: 20 }] },
          { name: "河北省", value: [{ name: "文科", value: 60 }, { name: "理科", value: 42 }] },
          { name: "山西省", value: [{ name: "文科", value: 40 }, { name: "理科", value: 41 }] },
          { name: "内蒙古自治区", value: [{ name: "文科", value: 23 }, { name: "理科", value: 24 }] },
          { name: "辽宁省", value: [{ name: "文科", value: 39 }, { name: "理科", value: 28 }] },
          { name: "吉林省", value: [{ name: "文科", value: 41 }, { name: "理科", value: 41 }] },
          { name: "黑龙江省", value: [{ name: "文科", value: 35 }, { name: "理科", value: 31 }] },
          { name: "上海市", value: [{ name: "文科", value: 12 }, { name: "理科", value: 12 }] },
          { name: "江苏省", value: [{ name: "文科", value: 47 }, { name: "理科", value: 45 }] },
          { name: "浙江省", value: [{ name: "文科", value: 57 }, { name: "理科", value: 57 }] },
          { name: "安徽省", value: [{ name: "文科", value: 57 }, { name: "理科", value: 52 }] },
          { name: "福建省", value: [{ name: "文科", value: 59 }, { name: "理科", value: 57 }] },
          { name: "江西省", value: [{ name: "文科", value: 49 }, { name: "理科", value: 42 }] },
          { name: "山东省", value: [{ name: "文科", value: 67 }, { name: "理科", value: 52 }] },
          { name: "河南省", value: [{ name: "文科", value: 69 }, { name: "理科", value: 68 }] },
          { name: "湖北省", value: [{ name: "文科", value: 60 }, { name: "理科", value: 56 }] },
          { name: "湖南省", value: [{ name: "文科", value: 62 }, { name: "理科", value: 52 }] },
          { name: "重庆市", value: [{ name: "文科", value: 47 }, { name: "理科", value: 44 }] },
          { name: "四川省", value: [{ name: "文科", value: 65 }, { name: "理科", value: 60 }] },
          { name: "贵州省", value: [{ name: "文科", value: 32 }, { name: "理科", value: 30 }] },
          { name: "云南省", value: [{ name: "文科", value: 42 }, { name: "理科", value: 41 }] },
          { name: "西藏自治区", value: [{ name: "文科", value: 5 }, { name: "理科", value: 4 }] },
          { name: "陕西省", value: [{ name: "文科", value: 38 }, { name: "理科", value: 42 }] },
          { name: "甘肃省", value: [{ name: "文科", value: 28 }, { name: "理科", value: 28 }] },
          { name: "青海省", value: [{ name: "文科", value: 5 }, { name: "理科", value: 5 }] },
          { name: "宁夏回族自治区", value: [{ name: "文科", value: 10 }, { name: "理科", value: 8 }] },
          { name: "新疆维吾尔自治区", value: [{ name: "文科", value: 36 }, { name: "理科", value: 31 }] },
          { name: "广东省", value: [{ name: "文科", value: 63 }, { name: "理科", value: 60 }] },
          { name: "广西省", value: [{ name: "文科", value: 29 }, { name: "理科", value: 30 }] },
          { name: "海南省", value: [{ name: "文科", value: 8 }, { name: "理科", value: 6 }] },
        ];

        /*获取地图数据*/
        var mapFeatures = this.$echarts.getMap(mapName).geoJson.features;
        mapFeatures.forEach(function (v) {
          // 地区名称
          var name = v.properties.name;
          // 地区经纬度
          geoCoordMap[name] = v.properties.center
        });

        console.log(data)
        console.log(toolTipData)
        var max = 480,
          min = 9; // todo 
        var maxSize4Pin = 100,
          minSize4Pin = 20;

        var convertData = function (data) {
          var res = [];
          for (var i = 0; i < data.length; i++) {
            var geoCoord = geoCoordMap[data[i].name];
            if (geoCoord) {
              res.push({
                name: data[i].name,
                value: geoCoord.concat(data[i].value),
              });
            }
          }
          return res;
        };
        this.myMiddleMapCharts = this.$echarts.init(document.querySelector('#middleMapTop'))
        // this.$echarts.registerMap('xuzhouMap', xuzhou);
        this.option = {
          tooltip: {
            padding: 0,
            enterable: true,
            transitionDuration: 1,
            textStyle: {
              color: '#000',
              decoration: 'none',
            },
            // position: function (point, params, dom, rect, size) {
            //   return [point[0], point[1]];
            // },
            formatter: function (params) {
              // console.log(params)
              var tipHtml = '';
              tipHtml = '<div style="width:280px;height:180px;background:rgba(22,80,158,0.8);border:1px solid rgba(7,166,255,0.7)">'
                + '<div style="width:100%;height:40px;line-height:40px;border-bottom:2px solid rgba(7,166,255,0.7);padding:0 20px">' + '<i style="display:inline-block;width:8px;height:8px;background:#16d6ff;border-radius:40px;">' + '</i>'
                + '<span style="margin-left:10px;color:#fff;font-size:16px;">' + params.name + '</span>' + '</div>'
                + '<div style="padding:20px">'
                + '<p style="color:#fff;font-size:12px;">' + '<i style="display:inline-block;width:10px;height:10px;background:#16d6ff;border-radius:40px;margin:0 8px">' + '</i>'
                + '单位总数：' + '<span style="color:#11ee7d;margin:0 6px;">' + toolTipData.length + '</span>' + '个' + '</p>'
                + '<p style="color:#fff;font-size:12px;">' + '<i style="display:inline-block;width:10px;height:10px;background:#16d6ff;border-radius:40px;margin:0 8px">' + '</i>'
                + '总人数：' + '<span style="color:#f48225;margin:0 6px;">' + toolTipData.length + '</span>' + '个' + '</p>'
                + '<p style="color:#fff;font-size:12px;">' + '<i style="display:inline-block;width:10px;height:10px;background:#16d6ff;border-radius:40px;margin:0 8px">' + '</i>'
                + '总人数：' + '<span style="color:#f4e925;margin:0 6px;">' + toolTipData.length + '</span>' + '个' + '</p>'
                + '<p style="color:#fff;font-size:12px;">' + '<i style="display:inline-block;width:10px;height:10px;background:#16d6ff;border-radius:40px;margin:0 8px">' + '</i>'
                + '总人数：' + '<span style="color:#25f4f2;margin:0 6px;">' + toolTipData.length + '</span>' + '个' + '</p>'
                + '</div>' + '</div>';

              return tipHtml;
            }

          },
          visualMap: {
            show: false,
            orient: 'horizontal',
            min: 0,
            max: 200,
            left: '10%',
            top: '82%',
            calculable: true,
            seriesIndex: [1],
            inRange: {
              color: ['#04387b', '#467bc0'] // 蓝绿
            }
          },
          geo: {
            show: true,
            map: mapName,
            zoom: 1.13, // 默认显示级别
            layoutSize: '115%',
            aspectScale: 0.76, //长宽比
            layoutCenter: ['50%', '67%'],
            label: {
              normal: {
                show: false
              },
              emphasis: {
                show: false,
              }
            },
            roam: false,
            itemStyle: {
              normal: {
                areaColor: '#023677',
                borderColor: '#1180c7',
              },
              emphasis: {
                areaColor: '#4499d0',
              }
            }
          },
          series: [{
            name: '散点',
            type: 'scatter',
            coordinateSystem: 'geo',
            data: convertData(data),
            symbolSize: function (val) {
              return val[2] / 10;
            },
            label: {
              normal: {
                formatter: '{b}',
                position: 'right',
                show: true
              },
              emphasis: {
                show: true
              }
            },
            itemStyle: {
              normal: {
                color: '#fff'
              }
            }
          },
          {
            type: 'map',
            map: mapName,
            geoIndex: 0,
            aspectScale: 0.75, //长宽比
            showLegendSymbol: false, // 存在legend时显示
            label: {
              normal: {
                show: true
              },
              emphasis: {
                show: false,
                textStyle: {
                  color: '#fff'
                }
              }
            },
            roam: true,
            itemStyle: {
              normal: {
                areaColor: '#031525',
                borderColor: '#3B5077',
              },
              emphasis: {
                areaColor: '#2B91B7'
              }
            },
            animation: false,
            data: data
          },
          {
            name: '点',
            type: 'scatter',
            coordinateSystem: 'geo',
            zlevel: 6,
          },
          {
            name: 'Top 5',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            data: convertData(data.sort(function (a, b) {
              return b.value - a.value;
            }).slice(0, 10)),
            symbolSize: function (val) {
              return val[2] / 10;
            },
            showEffectOn: 'render',
            rippleEffect: {
              brushType: 'stroke'
            },
            hoverAnimation: true,
            label: {
              normal: {
                formatter: '{b}',
                position: 'left',
                show: false
              }
            },
            itemStyle: {
              normal: {
                color: 'yellow',
                shadowBlur: 10,
                shadowColor: 'yellow'
              }
            },
            zlevel: 1
          },

          ]
        };
        this.myMiddleMapCharts.setOption(this.option)
      })
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.middleMapTop {
  width: 100%;
  height: 100%;
  position: relative;

  .middleMapTop-uv {
    position: absolute;
    // width: 365px;
    height: 20%;
    top: 0%;
    left: 25%;
    // background-color: red;
    // padding-left: 50px;
    font-size: 15px;
    font-weight: 700;
    z-index: 999;

    .middleMapTop-uv-title {
      margin: 30px 0 17px 0;
    }

    .middleMapTop-uv-num {
      display: flex;
      align-items: center;

      .middleMapTop-uv-num-item {
        width: 32px;
        height: 68px;
        border-radius: 5px;
        background: #173B75;
        box-sizing: border-box;
        border: 1px solid #11A7FC;
        font-size: 20px;
        color: #FF6A2C;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
      }
    }
  }

  .middleMapTop-pv {
    position: absolute;
    // width: 365px;
    height: 20%;
    top: 0%;
    left: 50%;
    // background-color: red;
    // padding-left: 50px;
    font-size: 15px;
    font-weight: 700;
    z-index: 999;

    .middleMapTop-pv-title {
      margin: 30px 0 17px 0;
    }

    .middleMapTop-pv-num {
      display: flex;
      align-items: center;

      .middleMapTop-pv-num-item {
        width: 32px;
        height: 68px;
        border-radius: 5px;
        background: #173B75;
        box-sizing: border-box;
        border: 1px solid #11A7FC;
        font-size: 20px;
        color: #FFCC15;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
      }
    }
  }
}

#middleMapTop {
  width: 100%;
  height: 100%;
  padding-top: 5%;

}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>