<!--  -->
<template>
  <div class="leftOne">
    <div class="chart-title">
      日负荷(kw)
    </div>
    <div id="leftOneCharts">

    </div>
  </div>
</template>

<script>
export default {
  name: "leftOne",
  props: {

  },
  watch: {

  },
  computed: {

  },
  data() {
    return {
      option: null,
      myLeftOneCharts: null
    }
  },
  created() {
    if (!this.myLeftOneCharts) {
      this.$nextTick(async () => {
        var todayData = ['34545','24324','23233','45353',];
        var yesterdayData = ['12321','23364','34342','32423','44344','56544','65465'];
        // 计算昨日和今日的最大电量
        var maxYesterday = Math.max.apply(Math, yesterdayData.map(function(item) {
          return item;
        }));
        var maxToday = Math.max.apply(Math, todayData.map(function(item) {
          return item;
        }));
        this.option = {
          grid:{
            x: '15%',
            y: '30%',
            y2: '20%'
          },
          tooltip: {
            trigger: 'axis',
            position: function (pt) {
              return [pt[0], '10%'];
            }
          },
          title: {
            top: 10,
            right: 5,
            text: [
              '{a|今日最大负荷：}',
              `{b|${maxToday}w\t}`,
              '{a|昨日最大负荷：}',
              `{b|${maxYesterday}w}`
            ],
            textStyle: {
              rich: {
                a: {
                  color: '#fff',
                  fontSize: 10,
                },
                b: {
                  fontSzie: 10,
                  color: '#fff'
                }
              }
            }
          },
          legend: {
            show:true,
            right: 10,
            top: 40,
            textStyle: {
              color: '#fff'
            },
            data: ['今日负荷','昨日负荷']
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false
            },
            axisLabel:{
              rotate: 45,
              textStyle: {
                color: '#fff'
              }
            },
            axisLine: {
              show:false
            },
            data: ['00:00','00:24','04:48','05:12','12:00','16:37','19:38']
          },
          yAxis: {
            type: 'value',
            boundaryGap: [0, '100%'],
            name: 'W',
            nameTextStyle: {
              color: '#fff'
            },
            axisLabel: {
              textStyle: {
                color: '#fff'
              }
            },
            splitLine: {
              show:false,
            }
          },
          series: [
            {
              name: '今日负荷',
              type: 'line',
              smooth: true,
              symbol: 'none',
              sampling: 'average',
              itemStyle: {
                normal: {
                  color: '#E8C852'
                }
              },
              areaStyle: {
                normal: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#E8C852'
                  }, {
                    offset: 1,
                    color: 'rgba(254, 221, 117, 0.1)'
                  }])
                }
              },
              markPoint: { //设置最大值和最小值
                label: {
                  color: '#fff'
                },
                data: [{
                  type: 'max',
                  name: '最大值'
                }, {
                  type: 'min',
                  name: '最小值'
                }]
             },
              data: todayData
            },
            {
              name: '昨日负荷',
              type: 'line',
              smooth: true,
              symbol: 'none',
              sampling: 'average',
              itemStyle: {
                normal: {
                  color: '#5BFFC4'
                }
              },
              areaStyle: {
                normal: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(91, 255, 196, 1)'
                  }, {
                    offset: 1,
                    color: 'rgba(91, 255, 196, 0.1)'
                  }])
                }
              },
              markPoint: { //设置最大值和最小值
                label: {
                  color: '#fff'
                },
                data: [{
                  type: 'max',
                  name: '最大值'
                }, {
                  type: 'min',
                  name: '最小值'
                }]
              },
              data: yesterdayData
            },
          ]
        };
        this.myLeftOneCharts = this.$echarts.init(document.querySelector('#leftOneCharts'))
        this.myLeftOneCharts.setOption(this.option)
      })
    }
  },
  mounted() {

  },
  methods: {
    resize() {
      this.myLeftOneCharts.resize()
    }
  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.leftOne {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.1%;
}

#leftOneCharts {
  width: 385px;
  height: 270px;
  background: url("../../assets/img/charts_bg.png");
  background-size: cover;
  padding-bottom: 4%;
}

.chart-title {
  font-size: 20px;
  font-family: FZZ;
  font-weight: 700;
  width: 385px;
  height: 37.17px;
  color: #fff;
  font-style: italic;
  padding-left: 70px;
  background: url("../../assets/img/charts_title.png");
  background-size: 440px 100%;
  margin-bottom: 12px;
}
</style>