<!--  -->
<template>
  <div class="leftTwo">
    <div class="chart-title">
      月电量(kWh)
    </div>
    <div id="leftTwoCharts">

    </div>
  </div>
</template>

<script>
export default {
  name: "leftTwo",
  props: {

  },
  watch: {

  },
  computed: {

  },
  data() {
    return {
      option: null,
      myLeftTwoCharts: null
    }
  },
  created() {
    if (!this.myLeftTwoCharts) {
      this.$nextTick(() => {
        var data1 = [400, 400, 300, 300, 300, 400, 400, 400, 300];
        var data2 = [400, 500, 500, 500, 500, 400, 400, 500, 500];
        var dataTotal = 0
        var lastDataTotal = 0
        data1.forEach(item => {
          dataTotal += +item
        })
        data2.forEach(item => {
          lastDataTotal += +item
        })
        this.option = {
          backgroundColor: 'rgba(0,0,0,0)',
          tooltip: {
            trigger: 'axis',
            axisPointer: { // 坐标轴指示器，坐标轴触发有效
              type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
            }
          },
          grid: {
            left: '2%',
            right: '4%',
            bottom: '8%',
            top: '30%',
            containLabel: true
          },
          title: {
            top: 10,
            right: 5,
            text: [
              "{a|本月累计电量:}",
              "{a|" + dataTotal + 'w' +"}",
              "{a|上月累计电量:}",
              "{a|" + lastDataTotal + 'w' + "}"
            ].join("\t"),
            textStyle: {
              color: "#fff",
              rich: {
                a: {
                  color: "#fff",
                  fontSize: 10,
                },
              },
            },
          },
          legend: {
            right: 10,
            top: 40,
            textStyle: {
              color: "#fff"
            },
            itemWidth: 12,
            itemHeight: 6,
            // itemGap: 35
          },
          xAxis: {
            type: 'category',
            data: ['1', '3', '5', '7', '9', '11', '13', '15'],
            axisLine: {
              show: false
            },
            axisLabel: {
              // interval: 0,
              // rotate: 40,
              textStyle: {
                fontFamily: 'Microsoft YaHei',
                color: '#fff'
              }
            },
            axisTick: {
              show: false
            }
          },

          yAxis: {
            type: 'value',
            max: '1200',
            name: 'W',
            axisLine: {
              show: false,
              lineStyle: {
                color: 'white'
              }
            },
            splitLine: {
              show: false
            },
            axisLabel: {}
          },
          series: [{
            name: '本月电力',
            type: 'bar',
            barWidth: '15%',
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#fccb05'
                }, {
                  offset: 1,
                  color: '#f5804d'
                }]),
                barBorderRadius: 12,
              },
            },
            markPoint: { //设置最大值和最小值
                label: {
                  color: '#fff'
                },
                data: [{
                  type: 'max',
                  name: '最大值'
                }, {
                  type: 'min',
                  name: '最小值'
                }]
             },
            data: data1
          },
          {
            name: '上月电力',
            type: 'bar',
            barWidth: '15%',
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#8bd46e'
                }, {
                  offset: 1,
                  color: '#09bcb7'
                }]),
                barBorderRadius: 11,
              }

            },
            markPoint: { //设置最大值和最小值
                label: {
                  color: '#fff'
                },
                data: [{
                  type: 'max',
                  name: '最大值'
                }, {
                  type: 'min',
                  name: '最小值'
                }]
             },
            data: data2
          },
        ]
        };

        // var app = {
        //   currentIndex: -1,
        // };
        // setInterval(function () {
        //   var dataLen = option.series[0].data.length;

        //   // 取消之前高亮的图形
        //   myChart.dispatchAction({
        //     type: 'downplay',
        //     seriesIndex: 0,
        //     dataIndex: app.currentIndex
        //   });
        //   app.currentIndex = (app.currentIndex + 1) % dataLen;
        //   //console.log(app.currentIndex);
        //   // 高亮当前图形
        //   myChart.dispatchAction({
        //     type: 'highlight',
        //     seriesIndex: 0,
        //     dataIndex: app.currentIndex,
        //   });
        //   // 显示 tooltip
        //   myChart.dispatchAction({
        //     type: 'showTip',
        //     seriesIndex: 0,
        //     dataIndex: app.currentIndex
        //   });


        // }, 1000);
        this.myLeftTwoCharts = this.$echarts.init(document.querySelector('#leftTwoCharts'))
        this.myLeftTwoCharts.setOption(this.option)
      })
    }
  },
  mounted() {

  },
  methods: {
    resize() {
      this.myLeftTwoCharts.resize()
    }
  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.leftTwo {
  display: flex;
  flex-direction: column;
}

#leftTwoCharts {
  width: 385px;
  height: 225px;
  background: url("../../assets/img/charts_bg.png");
  background-size: cover;
  padding-bottom: 4%;
}

.chart-title {
  font-size: 20px;
  font-family: FZZ;
  font-weight: 700;
  width: 385px;
  height: 37.17px;
  color: #fff;
  font-style: italic;
  padding-left: 70px;
  background: url("../../assets/img/charts_title.png");
  background-size: 440px 100%;
  margin-bottom: 12px;
}
</style>