<!--  -->
<template>
  <div class="rightTwo">
    <!-- <div class="hot-topics">
      今日热门板块分析
    </div> -->
    <div id="rightTwoCharts">

    </div>
  </div>
</template>

<script>
export var data = [
  { value: 1140, name: '行风热线' },
  { value: 388, name: '张慧帮你问' },
  { value: 326, name: '12345' },
  { value: 300, name: '推广活动' }
]
export default {
  name: "rightTwo",
  props: {

  },
  watch: {

  },
  computed: {

  },
  data() {
    return {
      option: null,
      myRightTherrCharts: null
    }
  },
  created() {
    if (!this.myRightTherrCharts) {
      this.$nextTick(async () => {
        this.option = {
          title: {
            text: '12个月内每月报警率',
            textStyle: {
              color: '#fff',
              fontSize: 14,
            },
            left: 'center',
            top: 25
          },
          tooltip: {
            trigger: 'axis'
          },
          // legend: {
          //   data: ['邮件营销']
          // },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11','12'],
            name: '月',
            nameTextStyle: {
              color: '#fff'
            },
            axisLabel: {
              textStyle:{
                color: '#fff'
              }
            },
            axisTick: {
              show: false
            },
            splitLine: { //设置X轴的网格
              show: false,
              interval: 'auto'
            },
            axisLine: { //设置X轴的颜色
              show: false,
            }
          },
          yAxis: {
            type: 'value',
            name: '告警率%',
            nameTextStyle: {
             color: '#fff'
            },
            axisLabel: {
              textStyle:{
                color: '#fff'
              }
            },
            splitLine: { //设置X轴的网格
              show: false,
              interval: 'auto'
            },
            axisLine: { //设置y轴的颜色
              show: false,
            }
          },
          series: [{
            name: '邮件营销',
            type: 'line',
            stack: '总量',
            smooth: true, //点与点之间的幅度,false为直线
            // symbol: 'circle',
            // symbolSize: 5,
            // showSymbol: true,   //是否显示点数据的圆点
            itemStyle: {    //设置折线的border颜色
                normal: {
                    color: '#FF8077',
                    borderColor: 'rgba(0,136,212,0.2)',
                    borderWidth: 10
                }
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(255, 158, 68,1)'
                }, {
                  offset: 1,
                  color: 'rgba(255, 70, 131,0.1)'
                }])
              }
            },
            label: { //设置点数据的提示
              normal: {
                show: false,
                position: 'bottom'
              }
            },
            data: [1.2, 1.3, 1.1, 1.4, 0.9, 1.3, 1.2, 1.5,1.2,1.2,1.6,1.3 ],
            markPoint: { //设置最大值和最小值
              data: [{
                type: 'max',
                name: '最大值'
              }, {
                type: 'min',
                name: '最小值'
              }]
            }

          }]
        };
        this.myRightTherrCharts = this.$echarts.init(document.querySelector('#rightTwoCharts'))
        this.myRightTherrCharts.setOption(this.option)
      })
    }
  },
  mounted() {

  },
  methods: {
    resize() {
      this.myRightTherrCharts.resize()
    }
  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.rightTwo {
  display: flex;
  flex-direction: column;
}

#rightTwoCharts {
  width: 385px;
  height: 274px;
  background: url("../../assets/img/charts_bg.png");
  background-size: cover;
  padding-bottom: 4%;
}

.hot-topics {
  font-size: 20px;
  font-family: FZZ;
  font-weight: 700;
  width: 385px;
  height: 37.17px;
  color: #fff;
  font-style: italic;
  font-weight: 700;
  padding-left: 70px;
  background: url("../../assets/img/charts_title.png");
  background-size: 440px 100%;
  margin-bottom: 12px;
}
</style>