<!--  -->
<template>
  <div class="rightOne">
    <div class="chart-title">
      电气设备告警率统计图
    </div>
    <div id="rightOneCharts">

    </div>
  </div>
</template>

<script>
export default {
  name: "rightOne",
  props: {

  },
  watch: {

  },
  computed: {

  },
  data() {
    return {
      option: null,
      myRightOneCharts: null
    }
  },
  created() {
    if (!this.myRightOneCharts) {
      this.$nextTick(async () => {

        this.option = {
          title: {
            text: '30天内每小时报警率',
            textStyle: {
              color: '#fff',
              fontSize: 14,
            },
            left: 'center',
            top: 25
          },
          tooltip: {
            trigger: 'axis'
          },
          // legend: {
          //   data: ['邮件营销']
          // },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11','12'],
            name: '时',
            nameTextStyle: {
              color: '#fff'
            },
            axisLabel: {
              textStyle:{
                color: '#fff'
              }
            },
            axisTick: {
              show: false
            },
            splitLine: { //设置X轴的网格
              show: false,
              interval: 'auto'
            },
            axisLine: { //设置X轴的颜色
              show: false,
            }
          },
          yAxis: {
            type: 'value',
            name: '告警率%',
            nameTextStyle: {
             color: '#fff'
            },
            axisLabel: {
              textStyle:{
                color: '#fff'
              }
            },
            splitLine: { //设置X轴的网格
              show: false,
              interval: 'auto'
            },
            axisLine: { //设置y轴的颜色
              show: false,
            }
          },
          series: [{
            name: '邮件营销',
            type: 'line',
            stack: '总量',
            smooth: true, //点与点之间的幅度,false为直线
            // symbol: 'circle',
            // symbolSize: 5,
            // showSymbol: true,   //是否显示点数据的圆点
            itemStyle: {    //设置折线的border颜色
                normal: {
                    color: '#FF8077',
                    borderColor: 'rgba(0,136,212,0.2)',
                    borderWidth: 10
                }
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(255, 158, 68,1)'
                }, {
                  offset: 1,
                  color: 'rgba(255, 70, 131,0.1)'
                }])
              }
            },
            label: { //设置点数据的提示
              normal: {
                show: false,
                position: 'bottom'
              }
            },
            data: [1.2, 1.3, 1.1, 1.4, 0.9, 1.3, 1.2, 1.5,1.2,1.2,1.6,1.3 ],
            markPoint: { //设置最大值和最小值
              data: [{
                type: 'max',
                name: '最大值'
              }, {
                type: 'min',
                name: '最小值'
              }]
            }

          }]
        };

        this.myRightOneCharts = this.$echarts.init(document.querySelector('#rightOneCharts'))
        this.myRightOneCharts.setOption(this.option)
      })
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.rightOne {
  display: flex;
  flex-direction: column;
}

#rightOneCharts {
  width: 385px;
  height: 250px;
  display: flex;
  align-items: center;
  background: url("../../assets/img/charts_bg.png");
  background-size: cover;
  padding-bottom: 4%;
}

.right-body {
  height: 190px;
  flex: 1;
  display: flex;
  flex-direction: column;

  .right-body-item {
    height: 44px;
    position: relative;
    display: flex;
    // align-items: center;

    .item-line1 {
      width: 80px;
      height: 4px;
      background: #73E9DA;
      margin-top: 9px;
      position: absolute;
      left: -65px;
      top: 0;
    }

    .item-line1::before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -3px;
      left: -5px;
      background: #73E9DA;
      border-radius: 50%;
    }

    .item-line1::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -3px;
      right: -5px;
      background: #73E9DA;
      border-radius: 50%;
    }

    .item-line2 {
      width: 80px;
      height: 4px;
      background: #7FD3FF;
      margin-top: 9px;
      position: absolute;
      left: -43px;
      top: 0;
    }

    .item-line2::before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -3px;
      left: -5px;
      background: #7FD3FF;
      border-radius: 50%;
    }

    .item-line2::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -3px;
      right: -5px;
      background: #7FD3FF;
      border-radius: 50%;
    }


    .item-line3 {
      width: 80px;
      height: 4px;
      background: #7FD3FF;
      margin-top: 9px;
      position: absolute;
      left: -15px;
      top: 0;
    }

    .item-line3::before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -3px;
      left: -5px;
      background: #7FD3FF;
      border-radius: 50%;
    }

    .item-line3::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -3px;
      right: -5px;
      background: #7FD3FF;
      border-radius: 50%;
    }


    .item-line4 {
      width: 80px;
      height: 4px;
      background: #7FD3FF;
      margin-top: 9px;
      position: absolute;
      left: 10px;
      top: 0;
    }

    .item-line4::before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -3px;
      left: -5px;
      background: #7FD3FF;
      border-radius: 50%;
    }

    .item-line4::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -3px;
      right: -5px;
      background: #7FD3FF;
      border-radius: 50%;
    }

    .item-text {
      position: absolute;
      top: 0;
      padding-left: 10px;

      .right-body-item-title {
        font-size: 12px;
        color: #FFFFFF;
      }

      .right-body-item-subTitle {
        font-size: 13px;
        font-weight: 700;

        color: #E3EAF2;
      }
    }

  }
}

.chart-title {
  font-size: 20px;
  font-family: FZZ;
  font-weight: 700;
  width: 385px;
  height: 37.17px;
  color: #fff;
  font-style: italic;
  padding-left: 70px;
  background: url("../../assets/img/charts_title.png");
  background-size: 440px 100%;
  margin-bottom: 12px;
}
</style>